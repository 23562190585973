<template>
  <BookingPosition
    displayedCard="pending">
    <div class="wrapper w100" v-if="this.position">
      <div class="navbar d-flex">
        <div>
          <b-link :to="{ name: 'positions-pending' }">&lt; Volver</b-link>
        </div>
        <div>
          <b-button
            variant="secondary"
            :disabled="!previous"
            :to="previous"
          >
            <img class="icon" :src="require('@/assets/icons/chevron-left.svg')">
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!next"
            :to="next"
          >
            <img class="icon" :src="require('@/assets/icons/chevron-right.svg')">
          </b-button>
        </div>
      </div>
      <DisplayHardwareBookingPosition
        :position="position"/>
    </div>
  </BookingPosition>

</template>

<script>
import BookingPosition from '@/views/BookingPosition'
import DisplayHardwareBookingPosition from '@/components/HardwareBooking/DisplayHardwareBookingPosition'
export default {
  name: 'HardwareBookingPositionPending',
  components: { DisplayHardwareBookingPosition, BookingPosition },
  data () {
    return {
      positions: [],
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  computed: {
    position () {
      return this.positions[this.getPositionIndex()]
    },
    next () {
      const index = 1 + this.getPositionIndex()
      if (this.positions.length === index) {
        return null
      }
      return {
        name: 'positions-inbox-position',
        params: {
          id: this.positions[index].getId
        }
      }
    },
    previous () {
      const index = this.getPositionIndex()
      if (index === 0) {
        return null
      }
      return {
        name: 'positions-inbox-position',
        params: {
          id: this.positions[index - 1].getId
        }
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$store.dispatch('hardwarePosition/getHardwarePositionPending')
        .then((res) => {
          this.positions = res
        })
    },
    getPositionIndex () {
      return this.positions.findIndex(position => position.getId === this.$route.params.id)
    }
  }
}
</script>

<style scoped>

</style>
